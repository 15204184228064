import { default as callbackXAKEEkagYnMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/airtable/callback.vue?macro=true";
import { default as _91slug_93vX3lA4sd01Meta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/features/[slug].vue?macro=true";
import { default as indexiHXM22ZnvBMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/features/index.vue?macro=true";
import { default as editim0SzCQuEpMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexsJHPQuLartMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analyticsQRsffLCkydMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexV9UgBVX6MpMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexRrep2Bi4DxMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as sharesnVc3YD36EMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showzHx0HeIFJjMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexku4oduLJjCMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/forms/create/index.vue?macro=true";
import { default as homeRC2xXeKlIhMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/home.vue?macro=true";
import { default as indexQCrZOgCmIqMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/index.vue?macro=true";
import { default as loginQudjwNKPjwMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/login.vue?macro=true";
import { default as callbackoCUBiguUK3Meta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/oauth/callback.vue?macro=true";
import { default as onboardinguYwEUGSg31Meta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/onboarding.vue?macro=true";
import { default as _91token_93wYPmi6u3tAMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexLOcQAQWmqSMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricing0MRNojypRVMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyo031Aye8YJMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/privacy-policy.vue?macro=true";
import { default as registerLtFjiry7CYMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/register.vue?macro=true";
import { default as access_45tokensropXt2NIA9Meta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountJyT2imcZ93Meta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/settings/account.vue?macro=true";
import { default as adminALWDcfRrDFMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/settings/admin.vue?macro=true";
import { default as billingUJm5zHgjXjMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionsIAxrAl0chcMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domainZQ8aCRHa0sMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as indexofVDtt0vIlMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/settings/index.vue?macro=true";
import { default as passwordPIUHDEBs6UMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/settings/password.vue?macro=true";
import { default as profileNRXigzsryZMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/settings/profile.vue?macro=true";
import { default as workspacesDy3qVSXWJEMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsNUeGeXvLzoMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/settings.vue?macro=true";
import { default as error4pfHaTM9yXMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successzTdClc5Z9LMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionskrWtDFfNacMeta } from "/codebuild/output/src591984180/src/airtableforms/client/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: "airtable-callback",
    path: "/airtable/callback",
    meta: callbackXAKEEkagYnMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/airtable/callback.vue").then(m => m.default || m)
  },
  {
    name: "features-slug",
    path: "/features/:slug()",
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: "features",
    path: "/features",
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editim0SzCQuEpMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showzHx0HeIFJjMeta?.name,
    path: "/forms/:slug()/show",
    meta: showzHx0HeIFJjMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show-analytics",
    path: "analytics",
    meta: analyticsQRsffLCkydMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexV9UgBVX6MpMeta?.redirect,
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexRrep2Bi4DxMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: sharesnVc3YD36EMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexku4oduLJjCMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homeRC2xXeKlIhMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginQudjwNKPjwMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardinguYwEUGSg31Meta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93wYPmi6u3tAMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password/reset",
    meta: indexLOcQAQWmqSMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerLtFjiry7CYMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsNUeGeXvLzoMeta?.name,
    path: "/settings",
    meta: settingsNUeGeXvLzoMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountJyT2imcZ93Meta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminALWDcfRrDFMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingUJm5zHgjXjMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionsIAxrAl0chcMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings-custom-domain",
    path: "custom-domain",
    meta: custom_45domainZQ8aCRHa0sMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexofVDtt0vIlMeta?.redirect,
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordPIUHDEBs6UMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profileNRXigzsryZMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspaces",
    path: "workspaces",
    meta: workspacesDy3qVSXWJEMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: error4pfHaTM9yXMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successzTdClc5Z9LMeta || {},
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    component: () => import("/codebuild/output/src591984180/src/airtableforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  }
]